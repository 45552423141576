<template>
      <div class="dynamicQrCodeEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'dynamicQrCode', params: {} }">
          Dynamic Qr Code
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'dynamicQrCodeEdit', params:{id: id} }">
            Edit Dynamic Qr Code
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Dynamic Qr Code</v-card-title>
          <v-card-text>
            <dynamicQrCode-form :id="id"></dynamicQrCode-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import DynamicQrCodeForm from './DynamicQrCodeForm.vue';

    export default {
      name: 'dynamicQrCode',
      data () {
        return {
          id: null
        }
      },
      components: {
        DynamicQrCodeForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    